import styled, { css } from "styled-components";
import React, { FC } from "react";

export const Headline: FC = ({ children }) => {
  return (
    <Root>
      <Dress>
        <HorizontalLine $direction="left" />
        <LeftGreenSlash />
        <LeftBlueSlash />
        <LeftRedSlash />
        <LeftWhiteSlash />
      </Dress>
      <Title>{children}</Title>
      <Dress>
        <RightWhiteSlash />
        <RightRedSlash />
        <RightBlueSlash />
        <RightGreenSlash />
        <HorizontalLine $direction="right" />
      </Dress>
    </Root>
  );
};
export default Headline;

const PC_HEIGHT = "40px";
const SP_HEIGHT = "30px";
const MARGIN = "26px";

const Title = styled.div`
  @media screen and (max-width: 960px) {
    font-size: ${SP_HEIGHT};
  }
  @media screen and (min-width: 961px) {
    font-size: ${PC_HEIGHT};
  }
`;
const Root = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 960px) {
    margin-bottom: 40px;
  }
  @media screen and (min-width: 961px) {
    max-width: 1280px;
    margin: 0 auto 40px;
  }
`;
const Dress = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  @media screen and (max-width: 960px) {
    height: ${SP_HEIGHT};
  }
  @media screen and (min-width: 961px) {
    height: ${PC_HEIGHT};
  }
`;
const Slash = styled.div`
  position: absolute;
  content: "";
  width: 10px;
  top: 0;
  transform: skewX(150deg);
  @media screen and (max-width: 960px) {
    height: ${SP_HEIGHT};
  }
  @media screen and (min-width: 961px) {
    height: ${PC_HEIGHT};
  }
`;
const WhiteSlash = styled(Slash)`
  background-color: #ffffff;
  z-index: 5;
`;
const LeftWhiteSlash = styled(WhiteSlash)`
  right: ${MARGIN};
`;
const RightWhiteSlash = styled(WhiteSlash)`
  left: ${MARGIN};
`;
const RedSlash = styled(Slash)`
  background-color: #ff0000;
  z-index: 4;
`;
const LeftRedSlash = styled(RedSlash)`
  right: calc(${MARGIN} + 2px);
`;
const RightRedSlash = styled(RedSlash)`
  left: calc(${MARGIN} + 2px);
`;
const BlueSlash = styled(Slash)`
  background-color: #0000ff;
  z-index: 3;
`;
const LeftBlueSlash = styled(BlueSlash)`
  right: calc(${MARGIN} + 6px);
`;
const RightBlueSlash = styled(BlueSlash)`
  left: calc(${MARGIN} + 6px);
`;
const GreenSlash = styled(Slash)`
  background-color: #00ff00;
  z-index: 2;
`;
const LeftGreenSlash = styled(GreenSlash)`
  right: calc(${MARGIN} + 8px);
`;
const RightGreenSlash = styled(GreenSlash)`
  left: calc(${MARGIN} + 8px);
`;
const HorizontalLine = styled.div<{ $direction: "right" | "left" }>`
  z-index: 5;
  background-color: white;
  position: absolute;
  content: "";
  @media screen and (max-width: 960px) {
    width: calc(100% - ${MARGIN} - 10px);
  }
  @media screen and (min-width: 961px) {
    width: calc(100% - ${MARGIN} - 12px);
  }
  height: 2px;
  ${({ $direction }) =>
    $direction === "left" &&
    css`
      bottom: 0;
      left: 0;
    `}
  ${({ $direction }) =>
    $direction === "right" &&
    css`
      top: 0;
      right: 0;
    `}
`;
