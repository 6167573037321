import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import Content from "components/front/corporate/atoms/Content";
import Headline from "components/front/corporate/atoms/Headline";

export type Props = HTMLAttributes<HTMLElement>;
const CompanySection: FC<Props> = ({ ...props }) => {
  return (
    <section {...props}>
      <Headline>会社概要</Headline>
      <Content>
        <DataRow>
          <dt>会社名</dt>
          <dd>アニメファンド株式会社</dd>
        </DataRow>
        <DataRow>
          <dt>設立</dt>
          <dd>2020年3月</dd>
        </DataRow>
        <DataRow>
          <dt>所在地</dt>
          <dd>〒271-0091 千葉県松戸市本町17−11 芹澤ビル6F</dd>
        </DataRow>
        <DataRow>
          <dt>資本金</dt>
          <dd>800万円</dd>
        </DataRow>
        <DataRow>
          <dt>代表取締役</dt>
          <dd>加納千稔</dd>
        </DataRow>
        <DataRow>
          <dt>事業概要</dt>
          <dd>
            クラウドファンディングの開発、運営、PR
            <br />
            ふるさと納税の開発、運営、PR
            <br />
            YouTubeチャンネルの運営
            <br />
            コンサルティング事業
            <br />
          </dd>
        </DataRow>
      </Content>
    </section>
  );
};
export default CompanySection;

const DataRow = styled.dl`
  border-bottom: 1px solid #777;

  &:first-of-type {
    border-top: 1px solid #777;
  }

  @media screen and (max-width: 960px) {
    padding: 10px 0;
  }
  @media screen and (min-width: 961px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //background-color: rgba(255, 255, 255, 0.1);
  }

  & > dt {
    font-size: 14px;
    @media screen and (max-width: 960px) {
      color: #aaa;
      //margin: 10px 0;
      padding: 10px 20px 5px;
    }
    @media screen and (min-width: 961px) {
      flex-basis: 40%;
      text-align: right;
      color: #ccc;
      font-size: 16px;
      padding: 30px;
      width: 200px;
    }
  }

  & > dd {
    line-height: 1.62em;
    @media screen and (max-width: 960px) {
      font-size: 16px;
      padding: 5px 20px 10px;
    }
    @media screen and (min-width: 961px) {
      flex-basis: 60%;
      text-align: left;
      padding: 30px;
      font-size: 18px;
    }
  }
`;
